import React, { Component } from "react";
import { DevPortalProvider } from "@stoplight/elements-dev-portal";
import "@stoplight/elements-dev-portal/styles.min.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ApiDocumentation from "./Components/Api_Documentation";
import ThemeDevelopment from "./Components/Theme_Development";
import HomePage from "./Components/Home_Page";
// import { Search } from './../src/Search';
//import './App.css';
import "./Assest/style/app.scss";

import "@stoplight/elements-dev-portal/styles.min.css";
// import { NotFound } from "./Components/not-found";

class App extends Component {
  render() {
    return (
      <DevPortalProvider>
        <BrowserRouter>
          <div className="app-container">
            <main className="main-content">
              <Switch>
                {/* <Route exact path="/"  component={HomePage} /> */}
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route path="/home" component={HomePage} />
                <Route path="/theme-docs" component={ThemeDevelopment} />
                <Route path="/api-docs" component={ApiDocumentation} />
                <Route component={HomePage} />
              </Switch>
            </main>
          </div>
        </BrowserRouter>
      </DevPortalProvider>
    );
  }
}

export default App;
