import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = {
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    }
  }
}
const queryClientConfig = new QueryClient(queryClient)
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClientConfig}>
    <App />

    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
