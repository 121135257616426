import React from "react";

const ThemeDevelopment = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 21.6667C13.7725 22.2167 12.7497 23.0333 11.9873 24.05C11.5596 24.6333 11.5596 25.3667 11.9873 25.95C12.7497 26.9667 13.7725 27.7833 14.9998 28.3333"
        stroke="#B79A3A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25 21.6667C26.2273 22.2167 27.2501 23.0333 28.0126 24.05C28.4403 24.6333 28.4403 25.3667 28.0126 25.95C27.2501 26.9667 26.2273 27.7833 25 28.3333"
        stroke="#B79A3A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0002 36.6667H25.0002C33.3335 36.6667 36.6668 33.3333 36.6668 25V15C36.6668 6.66666 33.3335 3.33333 25.0002 3.33333H15.0002C6.66683 3.33333 3.3335 6.66666 3.3335 15V25C3.3335 33.3333 6.66683 36.6667 15.0002 36.6667Z"
        stroke="#B79A3A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3335 13.35L35.0002 13.3333"
        stroke="#B79A3A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ThemeDevelopment;
