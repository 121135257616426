import React, { useEffect } from "react";
import { StoplightProject } from "@stoplight/elements-dev-portal";
import "@stoplight/elements-dev-portal/styles.min.css";
import Navigation from "../Navigation";
import $ from "jquery";

function ApiDocumentation() {
  const jQuerycode = () => {
    $(document).ready(function () {
      $(".toggle-menu").click(function (event) {
        $(".sl-elements-api > .sl-flex").addClass("menu-opened");
        event.stopPropagation();
        // Attach the click event handler to the "box" element after opening the menu
        const box = $(".menu-opened + div");
        box.click(function () {
          if ($(".sl-elements-api > .sl-flex").hasClass("menu-opened")) {
            $(".sl-elements-api > .sl-flex").removeClass("menu-opened");
          }
        });
      });
    });
  };
  useEffect(() => {
    jQuerycode();
  },[]);
  return (
    <>
      <Navigation />
      <div className="dev">
        <button className="toggle-menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            focusable="false"
            viewBox="0 0 16 16"
            class="icon-menu"
          >
            <path
              stroke-linecap="round"
              d="M1.5 3.5h13m-13 4h13m-13 4h13"
            ></path>
          </svg>
        </button>
        <StoplightProject
          basePath="api-docs"
          platformUrl="https://stoplight.io"
          projectId="cHJqOjE4MjYxOA"
        />
      </div>
    </>
  );
}

export default ApiDocumentation;
