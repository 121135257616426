import React, { useEffect } from "react";
import { StoplightProject } from "@stoplight/elements-dev-portal";
import "@stoplight/elements-dev-portal/styles.min.css";
import Navigation from "../Navigation";
import Footer from "./Footer";
import "@stoplight/elements-dev-portal/styles.min.css";
import $ from "jquery";

function ThemeDevelopment() {
  const jQuerycode = () => {
    $(document).ready(function (e) {
      $(".toggle-menu").click(function (event) {
        $(".sl-elements-api > .sl-flex").addClass("hi");
        event.stopPropagation();
      });
      $(document).click(function(event){
        if (!$(event.target).hasClass('hi')) {
            $(".sl-elements-api > .sl-flex").removeClass("hi");
        }
    });
    });
  };
  useEffect(() => {
    jQuerycode();
  });
  return (
    <>
      <Navigation />
      <div className="dev">
        <button className="toggle-menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            focusable="false"
            viewBox="0 0 16 16"
            class="icon-menu"
          >
            <path
              stroke-linecap="round"
              d="M1.5 3.5h13m-13 4h13m-13 4h13"
            ></path>
          </svg>
        </button>
        <StoplightProject basePath="theme-docs" platformUrl="https://stoplight.io" projectId="cHJqOjE4MTA5Mg" />
      </div>
      <Footer />
    </>
  );
}

export default ThemeDevelopment;
