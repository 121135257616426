import React from "react";
import Navigation from "../Navigation";
import heroBanner from "../Assest/images/Headless CMS - Hero Banner.png";
import "@stoplight/elements-dev-portal/styles.min.css";
import Footer from "./Footer";
import ApiDocumatation from "src/Assest/icons/ApiDocumatation";
import ThemeDevelopment from "src/Assest/icons/ThemeDevelopment";

function HomePage() {
  const ourPartner = () => {
    console.log("Click me our partner");
  };
  return (
    <div>
      <Navigation />
      <div className="body">
        <div className="hero-banner">
          <span className="banner-bg"></span>
          <h1>Welcome to developer portal</h1>
          <button
            className="btn"
            onClick={() => {
              ourPartner();
            }}
          >
            Become our partner
          </button>
        </div>
        <div className="container">
          <div class="banner-blocks">
            <div class="container">
              <section class="categories blocks">
                <ul class="blocks-list">
                  <li class="blocks-items">
                    <div class="block-content">
                      <ApiDocumatation />
                      <h3>
                        <a href="/api-docs/9maocpby81ng1-about-our-ap-is">
                          API Documentation
                        </a>
                      </h3>
                      <p>
                        Develop Advanced Apps and Seamlessly Integrate with
                        Experro using our Experro API Documentation.
                      </p>
                    </div>
                  </li>
                  <li class="blocks-items">
                    <div class="block-content">
                      <ThemeDevelopment />
                      <h3>
                        <a href="/theme-docs/6y0iz614xzwsj-introduction-to-experro-base-theme">
                          Theme Development
                        </a>
                      </h3>
                      <p>
                        Craft Innovative Storefront Experiences with Experro's
                        Theme Development Documentation.
                      </p>
                    </div>
                  </li>
                </ul>
              </section>
            </div>
          </div>
          <div className="theme-development">
            <h2>Theme Development</h2>
            <p>
              Craft Innovative Storefront Experiences with Experro's Theme
              Development Documentation.
            </p>
          </div>
          <div className="theme-content flex justify-center align-v-center">
            <div>
              <img src={heroBanner} alt="herobanner" />
            </div>
            <div>
              <ul className="list-none">
                <li>
                  <h4>
                    <a href="/theme-docs/6y0iz614xzwsj-introduction-to-experro-base-theme">
                      Getting Started
                    </a>
                  </h4>
                  <p>Learn how to get up the speed on Experro's base theme.</p>
                </li>
                <li>
                  <h4>
                    <a href="/theme-docs/r7pjhv2m2jgay-base-theme-introduction">
                      Overview
                    </a>
                  </h4>
                  <p>Get familar with Experro's base theme code </p>
                </li>
                <li>
                  <h4>
                    <a href="/theme-docs/2pb7fxn0p4n8i-about-cli">
                      Experro CLI
                    </a>
                  </h4>
                  <p>Learn how to build Experro's base theme and deploy it to Storefront.</p>
                </li>
                <li>
                  <h4>
                    <a href="/theme-docs/lbtlsah4oydn0-about-theme-templates">
                      Theme Templates
                    </a>
                  </h4>
                  <p>Explore the power of existing templates and create your custamized theme template </p>
                </li>
                <li>
                  <h4>
                    <a href="/theme-docs/hu66r9pub325l-about-theme-components">
                      Theme Components
                    </a>
                  </h4>
                  <p>Explore the existing components and construct your own theme components</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="book-demo-section common-mt-mb">
            <div class="container">
              <div class="main-book">
                <div class="book-wrap">
                  <h2 class="center-heading">
                    Join the Experro Partner Program
                  </h2>
                </div>
                <div class="book-wrap button-box">
                  <div class="link-wrap">
                    <a className="btn" href="/Partner">
                      Become our Partner
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
