import {
  Search as ElementsSearch,
  useGetNodes,
} from "@stoplight/elements-dev-portal";
import React, { useCallback } from "react";

export const Search = ({ projectIds }) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { data } = useGetNodes({
    search,
    projectIds,
  });

  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };
  const handleClick = useCallback((data) => {
    window.location.href = `https://developer.experro.com/${data.project_slug}/${data.slug}`;
  }, []);

  return (
    <div className="flex search-btn">
      <button style={{ color: "white" }} onClick={() => setOpen(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            d="M8.73026 15.873C12.6752 15.873 15.8731 12.675 15.8731 8.73014C15.8731 4.78525 12.6752 1.58728 8.73026 1.58728C4.78537 1.58728 1.5874 4.78525 1.5874 8.73014C1.5874 12.675 4.78537 15.873 8.73026 15.873Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.2539 18.254L14.2856 14.2857"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <ElementsSearch
        search={search}
        onSearch={setSearch}
        onClick={handleClick}
        onClose={handleClose}
        isOpen={open}
        searchResults={data}
      />
    </div>
  );
};
