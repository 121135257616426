import Navbar from "react-bootstrap/Navbar";
import { Search } from "./Search";
import Logo from "./Assest/icons/Logo";
import React, { useState } from "react";

function Navigation() {
  // const [toggle, setToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setMobileToggle(false);
  };
  const Modal = () => {
    return (
      <>
        {mobileToggle && (
          <div className="mobile-menu">
            <ul>
              <li>
                <a
                  href="https://support.experro.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Help Center
                </a>
              </li>
              <li className={open ? "open" : "close"}>
                <div className="select">
                  <button className="dev-center" onClick={() => setOpen(!open)}>
                    Dev Center
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                    >
                      <path
                        d="M9.5 1L5.5 5L1.5 1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  {open && (
                    <ul>
                      <li>
                        <a href="/api-docs/9maocpby81ng1-about-our-ap-is">
                          API Documentation
                        </a>
                      </li>
                      <li>
                        <a href="/theme-docs/6y0iz614xzwsj-introduction-to-experro-base-theme">
                          Theme Development
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <li>
                <a className="contact" href="/contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  };
  return (
    <header className="header">
      <div className="container">
        <Navbar>
          <a href="/">
            <Logo />
          </a>
          <div className="header-center flex">
            <a
              className="help-center"
              href="https://support.experro.com"
              target="_blank"
              rel="noreferrer"
            >
              Help Center
            </a>
            <div className="select">
              {/* <div className={toggle ? "open" : "close"}> */}
              <button
                className="dev-center"
                // onClick={() => setToggle(!toggle)}
              >
                Dev Center
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="6"
                  viewBox="0 0 11 6"
                  fill="none"
                >
                  <path
                    d="M9.5 1L5.5 5L1.5 1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              {/* </div> */}
              {/* {toggle && ( */}
              <ul>
                <li>
                  <a href="/api-docs/9maocpby81ng1-about-our-ap-is">API Documentation</a>
                </li>
                <li>
                  <a href="/theme-docs/6y0iz614xzwsj-introduction-to-experro-base-theme">Theme Development</a>
                </li>
              </ul>
              {/* )} */}
            </div>
          </div>
          <div className="header-right">
            <div>
              {!mobileToggle ? (
                <button
                  class="menu-button-mobile open"
                  // aria-controls="user-nav-mobile"
                  // aria-expanded="false"
                  // aria-label="Toggle navigation menu"
                  onClick={() => setMobileToggle(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    focusable="false"
                    viewBox="0 0 16 16"
                    class="icon-menu"
                  >
                    <path
                      stroke-linecap="round"
                      d="M1.5 3.5h13m-13 4h13m-13 4h13"
                    ></path>
                  </svg>
                </button>
              ) : (
                <button
                  class="menu-button-mobile close"
                  onClick={() => setMobileToggle(false)}
                >
                  <svg
                    width="20px"
                    height="20px"
                    class="cancel-icon icon-menu"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 2"
                    id="b1bec25a-a443-4da7-b443-3916ea7ea246"
                    viewBox="0 0 35 35"
                  >
                    <path d="M28.814,30.064a1.247,1.247,0,0,1-.884-.367L5.3,7.07A1.249,1.249,0,0,1,7.07,5.3L29.7,27.93a1.251,1.251,0,0,1-.884,2.134Z"></path>
                    <path d="M6.186,30.064A1.251,1.251,0,0,1,5.3,27.93L27.93,5.3A1.25,1.25,0,0,1,29.7,7.07L7.07,29.7A1.247,1.247,0,0,1,6.186,30.064Z"></path>
                  </svg>
                </button>
              )}
            </div>
            <Navbar.Collapse id="navbarScroll">
              <Search projectIds={["cHJqOjE4MTA5Mg"]} />
            </Navbar.Collapse>
            <a
              className="contact"
              href="https://www.experro.com/contact/"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
            <a
              className="login btn"
              href="https://admin.experro.app"
              target="_blank"
              rel="noreferrer"
            >
              Login
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26003 15 3.41003 18.13 3.41003 22"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </Navbar>
      </div>
      <Modal
        onClose={handleClose}
        open={mobileToggle}
        style={{
          position: "absolute",
          border: "2px solid #000",
          backgroundColor: "gray",
          boxShadow: "2px solid black",
          height: 80,
          width: 240,
          margin: "auto",
        }}
      ></Modal>
    </header>
  );
}

export default Navigation;
