import React from "react";

function Footer() {
  return (
    <footer class="footer">
      <div className="container">
        <div class="footer-inner">
          <div class="footer-main-section">
            <div class="footer-first-box">
              <div class="wrap-box">
                <div class="sub-box-footer">
                  <h2 class="center-heading">Take a hand, lend a hand</h2>
                  <p>
                    Join our community to share your feature requests and ask
                    expert users for advice or share your own.
                  </p>
                </div>
                <div class="sub-box-footer">
                  <a href="https://www.experro.com/contact/" class="btn" target="_blank">
                    Contact Support
                  </a>
                </div>
              </div>
            </div>
            <div class="copyright-footer-box">
              <div class="wrap-box">
                <div class="sub-box-footer">
                  <ul class="footer-links list-none">
                    <li>© 2023 experro. All rights reserved.</li>
                    <li>
                      <a href="https://www.experro.com/privacy-policy/" target="_blank">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="https://www.experro.com/terms-of-services/" target="_blank">Terms of Services</a>
                    </li>
                  </ul>
                </div>
                <div class="sub-box-footer">
                  <ul class="footer-links social-links list-none">
                    <li>
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="16"
                          viewBox="0 0 11 16"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4534_52145)">
                            <path
                              d="M8.95937 8.9375L9.38718 6.22285H6.71229V4.46123C6.71229 3.71855 7.08595 2.99463 8.28395 2.99463H9.5V0.683398C9.5 0.683398 8.39647 0.5 7.34138 0.5C5.13853 0.5 3.69865 1.8002 3.69865 4.15391V6.22285H1.25V8.9375H3.69865V15.5H6.71229V8.9375H8.95937Z"
                              fill="#fff"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_4534_52145">
                              <rect
                                width="9.75"
                                height="15"
                                fill="white"
                                transform="translate(0.5 0.5)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path d="M3.5 5H0.5V15.5H3.5V5Z" fill="white"></path>
                          <path
                            d="M11.7871 5C9.92857 5 9.41321 5.59896 9 6.36957V5H5.75V15.5H9V9.56522C9 8.65217 9 7.73913 10.625 7.73913C12.25 7.73913 12.25 8.65217 12.25 9.56522V15.5H15.5V9.56522C15.5 6.82609 15.0357 5 11.7871 5Z"
                            fill="white"
                          ></path>
                          <path
                            d="M2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5Z"
                            fill="white"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4534_52153)">
                            <path
                              d="M11.1344 7.84875C11.2104 8.09407 11.2514 8.35469 11.2514 8.62471C11.2514 10.0727 10.0708 11.25 8.62667 11.25C7.47088 11.25 6.48388 10.4959 6.13584 9.45241C6.12379 9.41629 6.11251 9.37982 6.10202 9.34302C6.03687 9.11465 6.00196 8.87368 6.00196 8.62471C6.00196 7.17676 7.17904 5.99942 8.62667 5.99942C9.80433 5.99942 10.8029 6.77858 11.1344 7.84875Z"
                              fill="#fff"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.1727 2.07758C16.0968 2.99837 16.3849 4.11596 16.4447 5.37765C16.5184 6.678 16.5184 10.572 16.4447 11.8723C16.3814 13.134 16.0933 14.2516 15.1727 15.1724C14.2521 16.0967 13.1348 16.3849 11.8734 16.4446C10.5733 16.5185 6.67667 16.5185 5.37662 16.4446C4.11521 16.3814 3.00138 16.0932 2.07729 15.1724C1.15319 14.2516 0.865072 13.134 0.80534 11.8723C0.731553 10.572 0.731553 6.67448 0.80534 5.37414C0.868586 4.11245 1.15319 2.99485 2.07729 2.07407C3.00138 1.15328 4.11873 0.865098 5.37662 0.805353C6.67667 0.731549 10.5733 0.731549 11.8734 0.805353C13.1348 0.868613 14.2521 1.1568 15.1727 2.07758ZM12.4847 7.42812C11.9765 5.77974 10.4446 4.58661 8.62667 4.58661C6.39198 4.58661 4.58947 6.38952 4.58947 8.62471C4.58947 9.04168 4.65219 9.4436 4.76875 9.82159C5.27701 11.4698 6.80885 12.6628 8.62667 12.6628C10.8614 12.6628 12.6639 10.8599 12.6639 8.62471C12.6639 8.20785 12.6012 7.80602 12.4847 7.42812ZM12.8291 5.36359C13.3491 5.36359 13.7708 4.94537 13.7708 4.42172C13.7708 3.90158 13.3491 3.47985 12.8291 3.47985C12.3091 3.47985 11.8874 3.90158 11.8874 4.42172C11.8874 4.94186 12.3056 5.36359 12.8291 5.36359Z"
                              fill="#fff"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_4534_52153">
                              <rect
                                width="15.75"
                                height="18"
                                fill="white"
                                transform="translate(0.75)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
