import React from "react";

const ApiDocumatation = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9999 33.3333H15.9998C9.33317 33.3333 6.6665 30.6667 6.6665 24V16C6.6665 9.33334 9.33317 6.66667 15.9998 6.66667H23.9998C30.6665 6.66667 33.3332 9.33334 33.3332 16V20.6251"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6665 13.2709C25.7842 12.148 24.3082 11.6667 22.0943 11.6667H17.2815C13.2708 11.6667 11.6665 13.2709 11.6665 17.2816V22.0945C11.6665 24.3084 12.1478 25.7843 13.2547 26.6667"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3335 6.66666V3.33333"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 6.66666V3.33333"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6665 6.66666V3.33333"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.3335 13.3333H36.6668"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3335 33.3333V36.6667"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3335 13.3333H6.66683"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3335 20H6.66683"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3335 26.6667H6.66683"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.4168 33.1667C30.3624 33.1667 32.7502 30.7789 32.7502 27.8334C32.7502 24.8878 30.3624 22.5 27.4168 22.5C24.4713 22.5 22.0835 24.8878 22.0835 27.8334C22.0835 30.7789 24.4713 33.1667 27.4168 33.1667Z"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.7502 34.1667L32.0835 32.5"
        stroke="#B79A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ApiDocumatation;
